html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Average Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100vh;
  background-color: #f3f4fb;
  overflow-x: hidden;
  position: relative
}

#root {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 20% auto 20%;

}

@media screen and (max-width: 1440px) {
  #root {
    grid-template-columns: auto;
  }
}